

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  border: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
  background-color: #f0f0f0;
  color: #444;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#app {
  width: 100%;
  height: 100%;

  font-family: Arial, Helvetica, sans-serif;
}
#three-container {
  width: 100%;
  height: 100%;
}

.resizable-x,
.resizable-y {
  display: flex;
  overflow: hidden;
}

.resizable-x {
  height: 100%;
}

.resizable-y {
  flex-direction: column;
}

.resizer-x,
.resizer-y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  padding: 4px;
}

.resizer-x {
  z-index: 2;
  cursor: col-resize;
}
.resizer-x::before,
.resizer-x::after {
  content: "";
  width: 2px;
  height: 16px;
  margin: 2px;
  background: lightgray;
}

.resizer-y {
  z-index: 1;
  cursor: row-resize;
  flex-direction: column;
}
.resizer-y::before,
.resizer-y::after {
  content: "";
  width: 16px;
  height: 2px;
  margin: 2px;
  background: lightgray;
}

.div0,
.div1,
.div2 {
  overflow: hidden;
}

.div0 {
  background: dodgerblue;
}

.div1 {
  background: rgb(224, 224, 224);
}

.div2 {
  background: rgb(235, 235, 235);
  overflow-y: scroll;
}

.editor { 
    height: calc(100% - 40px);
}
.tabs {
    height: 40px;
    min-width:300px;
    overflow: hidden;
    background-color: rgb(226, 226, 226);
}
.tabs div {
    display: inline-block;
    height:40px;
    padding:10px 10px;
    border-bottom: solid gray 1px;
    margin:0px
}
.tabs div:hover {
    background-color: rgb(179, 179, 179);
    cursor: pointer;
}
.tabs div.active {
    background-color: rgb(255, 255, 255);
    border-bottom: solid white 1px;
}

#error-log {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 989999;
    width: 600px;
    height: 80%;
    background: #000000bf;
    color: white;
    padding: 20px;
}

#error-log pre {
width: 600px;
white-space: pre-wrap;
}

textarea.view {
    resize: none;
}


#canvas-round-info {
  cursor: pointer;
}

.myMarker {
  position:absolute;
  background:rgba(34, 74, 98, 0.5);
  z-index:20
}

.div0 {
  position: relative;
}
#initial-configurations-selector-container {
  position: absolute;
  bottom: 0;
  right: 0;
}


#gui { position: absolute; top: 0px; left: 0px }

#doc-link-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #d8d8d8;
}
#doc-link-wrapper a {
  color: black;
}