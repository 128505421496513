html, body {
  box-sizing: border-box;
  color: #444;
  background-color: #f0f0f0;
  border: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

*, :before, :after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

#three-container {
  width: 100%;
  height: 100%;
}

.resizable-x, .resizable-y {
  display: flex;
  overflow: hidden;
}

.resizable-x {
  height: 100%;
}

.resizable-y {
  flex-direction: column;
}

.resizer-x, .resizer-y {
  background: #000;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
  position: relative;
}

.resizer-x {
  z-index: 2;
  cursor: col-resize;
}

.resizer-x:before, .resizer-x:after {
  content: "";
  background: #d3d3d3;
  width: 2px;
  height: 16px;
  margin: 2px;
}

.resizer-y {
  z-index: 1;
  cursor: row-resize;
  flex-direction: column;
}

.resizer-y:before, .resizer-y:after {
  content: "";
  background: #d3d3d3;
  width: 16px;
  height: 2px;
  margin: 2px;
}

.div0, .div1, .div2 {
  overflow: hidden;
}

.div0 {
  background: #1e90ff;
}

.div1 {
  background: #e0e0e0;
}

.div2 {
  background: #ebebeb;
  overflow-y: scroll;
}

.editor {
  height: calc(100% - 40px);
}

.tabs {
  background-color: #e2e2e2;
  min-width: 300px;
  height: 40px;
  overflow: hidden;
}

.tabs div {
  border-bottom: 1px solid gray;
  height: 40px;
  margin: 0;
  padding: 10px;
  display: inline-block;
}

.tabs div:hover {
  cursor: pointer;
  background-color: #b3b3b3;
}

.tabs div.active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

#error-log {
  z-index: 989999;
  color: #fff;
  background: #000000bf;
  width: 600px;
  height: 80%;
  padding: 20px;
  position: absolute;
  top: 40px;
  left: 0;
}

#error-log pre {
  white-space: pre-wrap;
  width: 600px;
}

textarea.view {
  resize: none;
}

#canvas-round-info {
  cursor: pointer;
}

.myMarker {
  z-index: 20;
  background: #224a6280;
  position: absolute;
}

.div0 {
  position: relative;
}

#initial-configurations-selector-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

#gui {
  position: absolute;
  top: 0;
  left: 0;
}

#doc-link-wrapper {
  background: #d8d8d8;
  position: absolute;
  bottom: 0;
  left: 0;
}

#doc-link-wrapper a {
  color: #000;
}
/*# sourceMappingURL=index.002bba5e.css.map */
